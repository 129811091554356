import axios from "axios";
import React, { Fragment } from "react";
import { ApiLink, notify } from "../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function DiemDanhLayout({ setUsername, setInfo, info }) {
  let navigate = useNavigate();
  const handleLogout = async () => {
    const response = await axios.get(`${ApiLink.domain + "/account/logout"}`, {
      withCredentials: true,
    });
    if (response.data.status) {
      notify(true, response.data.message);
      setUsername("");
      setInfo(null);
      return navigate("/", { replace: true });
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
      }
    }
  };

  const handleCheckin = async () => {
    const response = await axios.post(
      `${ApiLink.domain + "/checkin"}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      const user = response.data.data;
      setInfo({
        ...info,
        xu: user.xu,
      });
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
      }
    }
  };

  return (
    <Fragment>
      <div className="text_hello">
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            cursor: "pointer",
          }}
        >
          Tài khoản:{" "}
          <span
            onClick={() => {
              handleLogout();
            }}
            style={{ color: "#007bff" }}
          >
            Đăng xuất
          </span>
        </b>
      </div>

      <div className="btn_create_username">
        <span
          className="btn_create_username-content"
          onClick={() => {
            handleCheckin();
          }}
        >
          <img
            src="/images/icons/gift.gif"
            alt="gift"
            style={{
              height: "30px",
            }}
          />
          Điểm danh
        </span>
      </div>
    </Fragment>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import "../../styles/App.css";
import axios from "axios";
import { ApiLink, convertToDate, notify, Title } from "../../utils/Title";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import Footer from "../../components/Layouts/BasicLayout/Footer";
import InputUsernameOrderLayout from "../../components/Layouts/BasicLayout/InputUsernameOrderLayout";
export default function OrderPage() {
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let [mobileAppList, setMobileAppList] = useState([]);
  let [usernameInput, setUsernameInput] = useState("");
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);
  let [check, isCheck] = useState(false);
  let [timeList, setTimeList] = useState([]);
  let [timeChoose, setTimeChoose] = useState(false);
  let [checkFirst, isCheckFirst] = useState(false);

  useEffect(() => {
    document.title = Title.order_check + Title.origin;
    const months = [];
    const currentDate = new Date();
    let current_month = currentDate.getMonth() + 1;
    let current_year = currentDate.getFullYear();

    for (let i = 0; i < 6; i++) {
      if (current_month === 0) {
        current_month = 12;
        months.push(`${current_month}/${current_year - 1}`);
        current_year--;
      } else if (current_month < 10) {
        months.push(`${"0" + current_month}/${current_year}`);
      } else {
        months.push(`${current_month}/${current_year}`);
      }
      current_month--;
    }

    setTimeList(months);
    if (months.length > 0) {
      setTimeChoose(months[0]);
    }
    isLoading(false);
  }, []);

  const getTimeThanhCong = (time) => {
    const date = new Date(Number(Date.parse(time)));
    const year = date.getFullYear();
    if (date.getMonth() + 1 === 12) {
      return "18/01/" + (year + 1);
    }
    return "18/" + String(date.getMonth() + 2).padStart(2, "0") + "/" + year;
  };

  async function checkUsername(username) {
    isCheck(true);
    isCheckFirst(true);
    const response = await axios.post(
      `${ApiLink.domain + "/check-order/guest/username"}`,
      {
        username,
        time: timeChoose,
      },
      {
        withCredentials: true,
      }
    );
    isCheck(false);
    if (response.data.status) {
      setMobileAppList(response.data.data); // status, data
    } else {
      notify(false, response.data.message);
    }
  }

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  return loading ? (
    <div></div>
  ) : (
    <div
      className="app_container"
      style={{
        minHeight: "100vh",
      }}
    >
      <UserBoardLayout
        Element={
          <InputUsernameOrderLayout
            checkUsername={checkUsername}
            setUsernameInput={setUsernameInput}
            usernameInput={usernameInput}
            timeList={timeList}
            timeChoose={timeChoose}
            setTimeChoose={setTimeChoose}
          />
        }
        info={info}
        setInfo={setInfo}
        username={username}
        setUsername={setUsername}
        usernameInput={usernameInput}
        setUsernameInput={setUsernameInput}
        checkUsername={checkUsername}
        handleShowNotify={handleSetShowNotify}
        showNotify={showNotify}
      />

      {showNotify && <NotifyLayout />}

      <div className="list_app">
        <div className="list_name">Kết quả kiểm tra trạng thái</div>
        {mobileAppList.map((item, index) => {
          return (
            <Fragment key={index}>
              <div
                className="one_app"
                style={{
                  marginTop: index === 0 ? "0px" : "10px",
                }}
              >
                <div className="one_app--top">
                  <img
                    className="one_app--top_left"
                    src="/images/image/kiem-tien-task-link.webp"
                    alt="image_app"
                  ></img>
                  <div className="one_app--top_right">
                    <div className="app_title">
                      <img
                        className="app_title_icon"
                        alt="app_title_icon"
                        src="https://kiemthecaofree.com/hot.gif"
                      ></img>
                      <span className="app_title_name">
                        {`#${index + 1} ${item?.utm_source}`}
                      </span>
                    </div>
                    <div className="app_price">
                      <span className="one_app_child_title">Tên ứng dụng:</span>
                      <span className="one_app_child_content">
                        {item.merchant}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Tiền thưởng:</span>
                      <span className="one_app_child_content">30.000đ</span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">
                        Thời gian click:
                      </span>
                      <span className="one_app_child_content">
                        {convertToDate(item.click_time)}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">
                        Thời gian ghi nhận:
                      </span>
                      <span className="one_app_child_content">
                        {convertToDate(item.confirmed_time)}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">
                        Cập nhật lần cuối:
                      </span>
                      <span className="one_app_child_content">
                        {convertToDate(item.update_time)}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Trạng thái:</span>
                      <span className="one_app_child_content">
                        {item.order_pending ? (
                          <span className="app_pending">Chờ duyệt</span>
                        ) : item.is_confirmed && item.order_approved ? (
                          <span className="app_approved">Thành công</span>
                        ) : item.order_approved ? (
                          <span className="app_after_pending">Tạm duyệt</span>
                        ) : (
                          <span className="app_reject">Bị hủy</span>
                        )}
                      </span>
                    </div>
                    {(item.is_confirmed && item.order_approved) ||
                    item.order_reject ? (
                      ""
                    ) : (
                      <div className="app_price">
                        <span className="one_app_child_title">
                          Thời gian xét duyệt:
                        </span>
                        <span className="one_app_child_content">
                          Chậm nhất {getTimeThanhCong(item.confirmed_time)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {index === mobileAppList.length - 1 ? (
                <div className="end_list">Đã tới cuối danh sách</div>
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
        {check ? (
          <div
            className="end_list"
            style={{
              lineHeight: "22px",
            }}
          >
            Đang tìm kiếm, Vui lòng chờ...
          </div>
        ) : (
          ""
        )}
        {check === false && checkFirst && mobileAppList.length === 0 ? (
          <div
            className="end_list"
            style={{
              lineHeight: "22px",
            }}
          >
            Không tìm thấy kết quả. Trạng thái có thể cập nhật trễ lên tới 12h.
          </div>
        ) : (
          ""
        )}
        {checkFirst === false && mobileAppList.length === 0 ? (
          <div
            className="end_list"
            style={{
              lineHeight: "22px",
            }}
          >
            Nhập username để kiểm tra trạng thái nhé!
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer margin_top={"15px"} />
    </div>
  );
}

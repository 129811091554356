import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormFarmStock({ closeFormAdd, loadData }) {
  let [farmStockList, setFarmStockList] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    async function getAllFarmStock() {
      const response = await axios.get(
        `${ApiLink.domain + "/user-farm-stock"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setFarmStockList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }
    getAllFarmStock();
  }, [navigate]);

  const sellFarmStock = async (seed_id) => {
    const response = await axios.post(
      `${ApiLink.domain + "/user-farm-stock/" + seed_id}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data);
      closeFormAdd();
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleSellFarmStock = async (seed_id) => {
    if (!seed_id) {
      notify(false, "Vui lòng chọn nông sản cần bán");
      return;
    }
    await sellFarmStock(seed_id);
  };


  return (
    <div
      className="row col-9 add_edit_class"
      style={{
        display: "block",
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        margin: 0,
      }}
    >
      <div
        className="bg-primary row"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
          margin: 0,
        }}
      >
        <div className="text-white add_book_class_header">Nhà kho</div>
        <div onClick={() => closeFormAdd()}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>

      <div
        className="row col-12 sroll_form style-15 mobile_min_width_100 mobile_no_wrap"
        style={{
          padding: "0px !important",
          display: "flex",
          flexDirection: "row",
          margin: 0,
        }}
      >
        <div
          className="row col-12 mobile_min_width_100"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="shop_list">
            {farmStockList.map((farm_stock, index) => {
              return (
                <div key={index} className="one_farm_seed">
                  <div className="one_farm_seed__top">
                    <img
                      id="raucu"
                      src={"/images/farm/item/" + farm_stock.FarmSeed.avatar + ".png"}
                      alt="rau_cu"
                    />
                    <div>
                      <b>{"[ " + farm_stock.FarmSeed.seed_name + " ]"}</b>
                      <div className="one_farm_seed__price">
                        Giá bán: <b>{farm_stock.FarmSeed.sell}</b> xu / cây
                      </div>
                    </div>
                  </div>{" "}
                  <div className="one_farm_seed__mid">
                    <div>Số lượng: {"[ " + farm_stock.quanty + " ]"}</div>
                    <div>
                      Thành tiền:{" "}
                      {"[ " +
                        Number(farm_stock.quanty) *
                          Number(farm_stock.FarmSeed.sell) +
                        " đồng ]"}
                    </div>
                  </div>
                  <div className="one_farm_seed__bottom">
                    <div
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      Bán luôn
                    </div>
                    <div
                      className="one_farm_seed__buy"
                      onClick={() => {
                        handleSellFarmStock(farm_stock.seed_id);
                      }}
                    >
                      Bán hết
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      ></div>
    </div>
  );
}

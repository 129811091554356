import React from "react";
import { Link } from "react-router-dom";

export default function UserHeaderLeft() {
  return (
    <div className="sidebar-header">
      <div className="d-flex justify-content-between">
        <div className="logo">
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src="/images/logo/logo.png" alt="Logo" />
          </Link>
        </div>
      </div>
    </div>
  );
}

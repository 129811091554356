import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "../../../styles/Custome.css";
import "../../../styles/Flex.css";
import { ADMIN_CODE } from "../../../utils/Title";

export default function AdminThemeLeft({
  active_id,
  role,
  showSidebar,
  handeleSetShowSideBar,
}) {
  console.log(role);
  return (
    <Fragment>
      <div
        onClick={() => {
          handeleSetShowSideBar(false);
        }}
        className={showSidebar ? "background_on" : "background_off"}
      ></div>
      <div id="sidebar">
        <div
          className={
            showSidebar
              ? "sidebar-wrapper sidebar_user_active"
              : "sidebar-wrapper sidebar_user_none_active"
          }
          id="style-15"
        >
          <div className="sidebar-header">
            <div className="d-flex justify-content-between">
              <div className="logo">
                <Link to="/admin/mobile-app" style={{ textDecoration: "none" }}>
                  <img src="/images/logo/logo.png" alt="Logo" />
                </Link>
              </div>
            </div>
          </div>
          <div className="sidebar-menu">
            <ul className="menu">
              <li className="sidebar-title">Danh Mục Quản Lý</li>
              {role === ADMIN_CODE ? (
                <Fragment>
                  {/* <li
              className={`sidebar-item ${
                active_id === "ADMIN_STATISTIC" ? "active" : ""
              } `}
            >
              <Link to="/admin/statistic" className="sidebar-link">
                <i className="fas fa-chart-bar" />
                <span>Statistics</span>
              </Link>
            </li> */}
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_MOBILE_APP" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/mobile-app" className="sidebar-link">
                      <i className="fas fa-book" />
                      <span>Ứng Dụng</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_STATUS_APP" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/status-app" className="sidebar-link">
                      <i className="fas fa-user-astronaut" />
                      <span>Trạng Thái</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_CATEGORY" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/category" className="sidebar-link">
                      <i className="fas fa-bookmark" />
                      <span>Danh Mục</span>
                    </Link>
                  </li>

                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_CUSTOMER" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/customer" className="sidebar-link">
                      <i className="fas fa-user-circle" />
                      <span>Customers</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_TASKLINK" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/task-link" className="sidebar-link">
                      <i className="fas fa-tasks" />
                      <span>Task Link</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_TASKHISTORY" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/task-history" className="sidebar-link">
                      <i className="fas fa-history" />
                      <span>Task History</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_CODELINK" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/code-link" className="sidebar-link">
                      <i className="fas fa-sms" />
                      <span>Task Code</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_XUHISTORY" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/history" className="sidebar-link">
                      <i className="fas fa-money-bill-alt" />
                      <span>Xu History</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_FARM_SEED" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/farm-seed" className="sidebar-link">
                      <i className="fas fa-seedling" />
                      <span>Farm Seed</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_FARMHISTORY" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/farm-history" className="sidebar-link">
                      <i className="fas fa-piggy-bank" />
                      <span>Farm History</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_ROLE" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/role" className="sidebar-link">
                      <i className="fas fa-house-user" />
                      <span>Quyền Hạn</span>
                    </Link>
                  </li>
                  {/* <li
                  className={`sidebar-item ${
                    active_id === "ADMIN_COUPON" ? "active" : ""
                  } `}
                >
                  <Link to="/admin/coupon" className="sidebar-link">
                    <i className="fas fa-tag"></i>
                    <span>Coupons</span>
                  </Link>
                </li> */}
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_ORDER_HISTORY" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/order-history" className="sidebar-link">
                      <i className="fas fa-tag"></i>
                      <span>Order History</span>
                    </Link>
                  </li>

                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_DEVICE_USER" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/device-user" className="sidebar-link">
                      <i className="fas fa-laptop"></i>
                      <span>Device User</span>
                    </Link>
                  </li>
                  <li
                    className={`sidebar-item ${
                      active_id === "ADMIN_OTP" ? "active" : ""
                    } `}
                  >
                    <Link to="/admin/otp" className="sidebar-link">
                      <i className="fas fa-code"></i>
                      <span>OTP SMS</span>
                    </Link>
                  </li>
                </Fragment>
              ) : (
                ""
              )}
            </ul>
          </div>
          <button className="sidebar-toggler btn x">
            <i data-feather="x" />
          </button>
        </div>
      </div>
    </Fragment>
  );
}

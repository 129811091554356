import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  ApiLink,
  convertToDate,
  FRONTEND_BASE,
  notify,
  Title,
} from "../../../utils/Title";
import UserLogo from "./UserLogo";
export default function ReferenceFriend({ username }) {
  let [friendList, setFriendList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.user_reference + Title.origin;
  }, []);

  useEffect(() => {
    const getMucTieuHomNay = async () => {
      const response = await axios.get(`${ApiLink.domain + "/history/ref"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setFriendList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const handleAPIAll = async () => {
      await Promise.all([Promise.resolve(getMucTieuHomNay())]);
    };
    handleAPIAll();
  }, [navigate]);

  const countFriendSuccess = () => {
    return (
      friendList.filter((user) => user.user_status !== 1).length * 100
    ).toLocaleString("vi");
  };

  return (
    <Fragment>
      <div id="main">
        <UserLogo />
        <div className="page-content">
          <section
            className="row"
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          >
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div
                    className="col-lg-12 stretch-card"
                    style={{ padding: 0 }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="ref_detail">
                          <div className="ref_friend">
                            <div>- Link giới thiệu: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div
                              style={{
                                color: "#28a745",
                              }}
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(
                                    FRONTEND_BASE +
                                      "/account/signup?ref=" +
                                      username.trim().toLowerCase()
                                  )
                                  .then(() => {
                                    notify(true, "Sao chép link thành công");
                                  })
                                  .catch(() => {
                                    notify(false, "Có lỗi xảy ra khi sao chép");
                                  });
                              }}
                            >
                              {FRONTEND_BASE +
                                "/account/signup?ref=" +
                                username.trim().toLowerCase()}
                            </div>
                          </div>
                          <div className="ref_friend">
                            <div>- Bạn đã giới thiệu: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div>{friendList.length + " bạn bè"}</div>
                          </div>

                          <div className="ref_friend">
                            <div>- Tiền thưởng đã nhận: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div>{countFriendSuccess() + " đồng"}</div>
                          </div>

                          <div className="ref_friend">
                            <div>- Quà giới thiệu: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div>{100 + " đồng / lượt mời thành công"}</div>
                          </div>

                          <div className="ref_friend">
                            <div>- Ghi chú: </div>
                            <span style={{ margin: "0px 2px" }}></span>
                            <div style={{ textWrap: "wrap" }}>
                              Mình sẽ tăng tiền thưởng nếu tìm được nguồn quảng
                              cáo nhé
                            </div>
                          </div>

                          <div
                            className="ref_friend"
                            style={{
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            <button
                              class="btn btn-success btn_change_status"
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(
                                    "Tham gia tải App, làm nhiệm vụ, chơi game kiếm tiền miễn phí, đổi thẻ cào, rút tiền mặt uy tín tại: " +
                                      FRONTEND_BASE +
                                      "/account/signup?ref=" +
                                      username.trim().toLowerCase()
                                  )
                                  .then(() => {
                                    notify(true, "Sao chép thành công");
                                  })
                                  .catch(() => {
                                    notify(false, "Có lỗi xảy ra khi sao chép");
                                  });
                              }}
                            >
                              Copy và chia sẻ
                            </button>
                          </div>
                        </div>

                        <h4
                          className="card-title"
                          style={{
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          Danh sách bạn bè giới thiệu
                        </h4>
                        <div className="table-responsive pt-3">
                          <table className="table table-bordered text-center">
                            <thead>
                              <tr>
                                <th>STT</th>
                                <th>Username</th>
                                <th>Thời gian mời</th>
                                <th>Số dư</th>
                                <th>Trạng thái</th>
                              </tr>
                            </thead>
                            <tbody>
                              {friendList.map((history, index) => {
                                if (
                                  index >= (page - 1) * maxShow &&
                                  index < page * maxShow
                                ) {
                                  return (
                                    <tr key={index} className="table-white">
                                      <td>{index + 1}</td>
                                      <td>{history.username}</td>
                                      <td>
                                        {convertToDate(history.createdAt)}
                                      </td>
                                      <td>
                                        {history.xu.toLocaleString("vi") +
                                          " đồng"}
                                      </td>
                                      {history.user_status === 1 ? (
                                        <td
                                          style={{
                                            color: "#007bff",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Chưa xác thực
                                        </td>
                                      ) : history.user_status === 2 ? (
                                        <td
                                          style={{
                                            color: "#28a745",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Đang hoạt động
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            color: "#dc3545",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Tạm khóa
                                        </td>
                                      )}
                                    </tr>
                                  );
                                } else return "";
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="flex_center pagination_mg"
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <div>Tổng số: {friendList.length}</div>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={
                              friendList.length <= maxShow
                                ? 1
                                : friendList.length % maxShow === 0
                                ? Math.floor(friendList.length / maxShow)
                                : Math.floor(friendList.length / maxShow) + 1
                            }
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToDate, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";

export default function FarmHistoryAdmin({ username }) {
  let [farmHistoryList, setFarmHistoryList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [keyword, setKeyword] = useState("");

  useEffect(() => {
    async function getAllXuHistory() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/history-farm"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setFarmHistoryList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllXuHistory();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return farmHistoryList.filter((item) => {
      return (
        item.note.toLowerCase().includes(keyword) ||
        convertToDate(item.createdAt).toLowerCase().includes(keyword) ||
        convertToDate(item.updatedAt).toLowerCase().includes(keyword) ||
        item.User.username.toLowerCase().includes(keyword) ||
        Number(item.farm_history_id) === Number(keyword)
      );
    });
  };

  return (
    <div id="main">
      <AdminLogo />
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm lịch sử nông trại"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách lịch sử nông trại
                        </h4>

                        <div style={{ display: "flex" }}></div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>Username</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Số dư đầu
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Số dư cuối
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Thời gian
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Ghi chú</th>
                              <th style={{ whiteSpace: "nowrap" }}>Số lượng</th>
                              <th style={{ whiteSpace: "nowrap" }}>Giá</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((history, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{history.farm_history_id}</td>
                                    <td>{history.User.username}</td>
                                    <td>
                                      {history.vnd_start.toLocaleString("vi") +
                                        " đồng"}
                                    </td>
                                    <td>
                                      {history.vnd_end.toLocaleString("vi") +
                                        " đồng"}
                                    </td>
                                    <td>{convertToDate(history.createdAt)}</td>
                                    <td>{history.note}</td>
                                    <td>{history.quanty}</td>
                                    <td>
                                      {history.price.toLocaleString("vi")}
                                    </td>
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

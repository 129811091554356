import React from "react";
import "../../styles/Error.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="_404_page">
      <div className="not-found-container">
        <div className="not-found-content">
          <img
            src="/images/logo/logo.png"
            alt="404"
            className="not-found-image"
          />

          <h2 className="not-found-subtitle">Ôi không!</h2>
          <p className="not-found-message">
            Có vẻ như bạn đã đi lạc. Trang bạn tìm kiếm không tồn tại.
          </p>
          <h1 className="not-found-title">404</h1>
          <div className="not-found-links">
            <Link to={"https://kiemthecaofree.com/"} className="not-found-link">
              Trang chủ
            </Link>
            <Link to={"https://kiemthecaofree.com/"} className="not-found-link">
              Giới thiệu
            </Link>
            <Link to={"https://kiemthecaofree.com/"} className="not-found-link">
              Liên hệ
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormAddTaskLink({ closeFormAdd, loadData }) {
  let navigate = useNavigate();
  let [taskState, setTaskState] = useState({
    xu: "",
    url: "",
    short_url: "",
    content: "",
    task_name: "",
    time_get: "",
    status: 1,
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (name === "time_get" || name === "xu" || name === "status") {
      value = Number(value);
    }
    setTaskState({ ...taskState, [name]: value });
  };

  console.log("taskState", taskState);

  const addTask = async (newTask) => {
    const response = await axios.post(
      `${ApiLink.domain + "/admin/task-link"}`,
      newTask,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeFormAdd(true, false);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleAddTask = async () => {
    console.log("useState", taskState);
    if (taskState.task_name.trim().length < 3) {
      notify(false, "Tên Task không hợp lệ <3");
      return;
    } else if (taskState.url.trim().length < 3) {
      notify(false, "Địa chỉ Url không hợp lệ <3");
      return;
    } else if (taskState.content.trim().length < 3) {
      notify(false, "Vui lòng điền nội dung Task >= 3 ký tự");
      return;
    } else if (!Number.isInteger(taskState.xu) || Number(taskState.xu) < 0) {
      notify(false, "Số xu không hợp lệ");
      return;
    } else if (
      !Number.isInteger(taskState.time_get) ||
      Number(taskState.time_get) < 0
    ) {
      notify(false, "Thời gian không hợp lệ");
      return;
    } else if (
      !Number.isInteger(Number(taskState.status)) ||
      Number(taskState.status) > 2 ||
      taskState.status < 1
    ) {
      notify(false, "Vui lòng chọn trạng thái Task");
      return;
    }
    const newTask = {
      task_name: taskState.task_name.trim(),
      content: taskState.content.trim(),
      short_url: taskState.short_url.trim(),
      url: taskState.url.trim(),
      status: Number(taskState.status),
      xu: Number(taskState.xu),
      time_get: Number(taskState.time_get),
    };
    console.log(newTask);
    await addTask(newTask);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">Thêm Nhiệm Vụ</div>
        <div onClick={() => closeFormAdd(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên Nhiệm Vụ</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="task_name"
                defaultValue={taskState.task_name}
                type="text"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Đường Dẫn</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="url"
                defaultValue={taskState.url}
                type="text"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Rút Gọn Link</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="short_url"
                type="text"
                defaultValue={taskState.short_url}
              />
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Số Xu</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="xu"
                type="number"
                defaultValue={taskState.xu}
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Thời Gian</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="time_get"
                type="number"
                defaultValue={taskState.time_get}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="status"
                defaultValue={taskState.status}
                aria-label="Default select example"
              >
                <option value={1}>Đang mở</option>
                <option value={2}>Đã đóng</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="col-12"
          style={{ padding: "0px 35px", margin: 0, marginBottom: 20 }}
        >
          <label className="form-label">Nội dung nhiệm vụ</label>
          <textarea
            className="form-control"
            id="user-address"
            name="content"
            type="text"
            rows="3"
            onChange={(e) => handleOnChange(e)}
            defaultValue={taskState.content}
            placeholder="VD: Các bước thực hiện"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleAddTask()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}

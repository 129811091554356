import React from "react";

export default function FormTaskLinkDetail({ itemChoose, closeForm }) {
  const taskState = {
    task_id: itemChoose.task_id,
    xu: itemChoose.xu,
    url: itemChoose.url,
    short_url: itemChoose.short_url,
    content: itemChoose.content,
    task_name: itemChoose.task_name,
    time_get: itemChoose.time_get,
    status: itemChoose.status,
  };

  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Chi Tiết Nhiệm Vụ
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên Nhiệm Vụ</label>
              <input
                disabled
                className="form-control showordisable"
                name="task_name"
                defaultValue={taskState.task_name}
                type="text"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Đường Dẫn</label>
              <input
                disabled
                className="form-control"
                name="url"
                defaultValue={taskState.url}
                type="text"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Rút Gọn Link</label>
              <input
                disabled
                className="form-control"
                name="short_url"
                type="text"
                defaultValue={taskState.short_url}
              />
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Số Xu</label>
              <input
                disabled
                className="form-control"
                name="xu"
                type="number"
                defaultValue={taskState.xu}
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Thời Gian</label>
              <input
                disabled
                className="form-control"
                name="time_get"
                type="number"
                defaultValue={taskState.time_get}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                disabled
                className="form-select"
                name="status"
                defaultValue={taskState.status}
                aria-label="Default select example"
              >
                <option value={1}>Đang mở</option>
                <option value={2}>Đã đóng</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="col-12"
          style={{ padding: "0px 35px", margin: 0, marginBottom: 20 }}
        >
          <label className="form-label">Nội dung nhiệm vụ</label>
          <textarea
            className="form-control"
            id="user-address"
            name="content"
            type="text"
            rows="3"
            disabled
            defaultValue={taskState.content}
            placeholder="VD: Các bước thực hiện"
          />
        </div>
      </div>
    </div>
  );
}

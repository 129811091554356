import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  ApiLink,
  CLIENT_ID_FRONTEND,
  Title,
  getDevice,
  notify,
} from "../../../../utils/Title";
import {
  GoogleLogin,
  googleLogout,
  GoogleOAuthProvider,
} from "@react-oauth/google";
function LoginLayout() {
  let navigate = useNavigate();
  let [user, setUser] = useState({
    username: "",
    password: "",
  });

  let [error, setError] = useState({
    username: "",
    password: "",
  });

  const client_id = CLIENT_ID_FRONTEND;
  googleLogout();

  useEffect(() => {
    document.title = Title.login + Title.origin;
  }, []);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      const regexUsername = /^[a-zA-Z0-9]+$/;
      if (!regexUsername.test(value)) {
        setError({
          ...error,
          [name]: "Username không hợp lệ.",
        });
      } else {
        setError({
          ...error,
          [name]: "",
        });
      }
    } else {
      if (value.trim() === "") {
        setError({
          ...error,
          [name]: "Vui lòng điền " + name,
        });
      } else {
        setError({
          ...error,
          [name]: "",
        });
      }
    }
    setUser({ ...user, [name]: value });
  };

  const loginApi = async (user) => {
    const { device_name, device_browser, device_ip, ip_detail } =
      await getDevice();
    const new_user = {
      ...user,
      device_name,
      device_browser,
      device_ip,
      ip_detail,
    };
    return await axios
      .post(`${ApiLink.domain + "/account/login"}`, new_user, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  };

  const handleLoginButton = async () => {
    const { username, password } = user;
    const regexUsername = /^[a-zA-Z0-9]+$/;
    let oldError = { ...error };
    let check = true;
    if (!regexUsername.test(username)) {
      oldError.username = "Username không hợp lệ.";
      check = false;
    }
    if (password.trim() === "") {
      oldError.password = "Vui lòng điền password.";
      check = false;
    }
    if (check === false) {
      setError({ ...oldError });
    } else {
      const result = await loginApi(user);
      if (!result.status) {
        notify(false, result.message);
      } else {
        notify(true, result.message);
        setUser({
          username: "",
          password: "",
        });
        if (result.userLogin.role === "ROLE_ADMIN") {
          return navigate("/admin/statistic", { replace: true });
        } else if (result.userLogin.role === "ROLE_CUSTOMER") {
          return navigate("/account/info", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }
  };
  return (
    <div className="signup_container_left">
      <h2>
        <b>Đăng Nhập Tài Khoản</b>
      </h2>
      <div className="form_signup">
        <div className="input_sign input_username flex-col">
          <label>Tên đăng nhập</label>
          <input
            type="text"
            name="username"
            onChange={(e) => handleChangeValue(e)}
            value={user.username}
          />
          {error.username.length === 0 ? (
            ""
          ) : (
            <div className="error">{error.username}</div>
          )}
        </div>
        <div className="input_sign input_password flex-col">
          <label>Mật khẩu</label>
          <input
            type="password"
            onChange={(e) => handleChangeValue(e)}
            name="password"
            value={user.password}
          />
        </div>
        {error.password.length === 0 ? (
          ""
        ) : (
          <div className="error">{error.password}</div>
        )}
        <div
          className="check_remember"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 7,
            color: "red",
          }}
        ></div>
        <div className="btn_signup_signin flex_center mg-10_0">
          <button className="btn" onClick={() => handleLoginButton()}>
            Đăng Nhập
          </button>
        </div>
      </div>
      <div className="login_signin">
        Bạn chưa có tài khoản <Link to="/account/signup">Đăng ký</Link>
      </div>
      <div className="losspass">
        <Link to="/account/lost-password">Quên mật khẩu</Link>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
      >
        <GoogleOAuthProvider clientId={client_id}>
          <GoogleLogin
            onSuccess={async (credentialResponse) => {
              // console.log(credentialResponse.credential);
              const { device_name, device_browser, device_ip, ip_detail } =
                await getDevice();
              const response = await axios.post(
                ApiLink.domain + "/account/login-google",
                {
                  token: credentialResponse.credential,
                  device: true,
                  device_name,
                  device_browser,
                  device_ip,
                  ip_detail,
                },
                {
                  withCredentials: true,
                }
              );
              notify(response.data.status, response.data.message);
              if (response.data.status) {
                if (response.data.userLogin.role === "ADMIN") {
                  return navigate("/admin/mobile-app", { replace: true });
                } else if (response.data.userLogin.role === "CUSTOMER") {
                  return navigate("/account/info", { replace: true });
                } else {
                  return navigate("/", { replace: true });
                }
              }
            }}
            onError={() => {
              notify(false, "Đăng nhập thất bại.");
            }}
          />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}

export default memo(LoginLayout);

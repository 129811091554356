import React, { Fragment } from "react";
import DiemDanhLayout from "./DiemDanhLayout";
import OpenLayout from "../OpenLayout";
import { Link } from "react-router-dom";

export default function UserBoardLayout({
  info,
  username,
  setInfo,
  setUsername,
  Element,
  handleShowNotify,
  showNotify,
}) {
  const showTime = () => {
    const d = new Date();
    let hour = d.getHours();
    return hour <= 10
      ? "sáng"
      : hour <= 12
      ? "trưa"
      : hour <= 17
      ? "chiều"
      : "tối";
  };

  return (
    <Fragment>
      <div
        className="logo_vip"
        style={{
          textAlign: "center",
          margin: "5px 0px 10px 0px",
        }}
      >
        <Link to={"/"}>
          <img className="logo_login" src="/images/logo/logo.png" alt="logo" />
        </Link>
      </div>
      <div
        style={{
          border: "2px dashed #17a2b8",
          padding: "10px 5px",
          marginBottom: "10px",
          borderRadius: "5px",
        }}
      >
        <div>
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            Chào buổi {showTime()}!
          </b>
          <b
            style={
              username ? { color: "rgb(0, 123, 255)" } : { color: "#c82333" }
            }
          >
            {username ? (
              <span>
                {username && info ? (
                  <Link to={"/account/info"}>{username}</Link>
                ) : (
                  username
                )}

                {info && info.role_id === 1 ? (
                  <Fragment>
                    <span
                      style={{
                        margin: "0px 5px",
                      }}
                    >
                      |
                    </span>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#dc3545",
                      }}
                      to={"/admin/mobile-app"}
                    >
                      Quản Lý
                    </Link>
                  </Fragment>
                ) : (
                  ""
                )}
              </span>
            ) : (
              "Chưa có username"
            )}
          </b>
        </div>
        {info ? (
          <Fragment>
            <div className="text_hello">
              <b
                style={{
                  color: "rgb(40, 167, 69)",
                  marginRight: "5px",
                }}
              >
                Số dư:{" "}
                <span style={{ color: "rgb(220, 53, 69)" }}>
                  {info.xu.toLocaleString("vi")} VNĐ
                </span>
                <span>{" | "}</span>
                <Link to={"/account/withdraw"} style={{ color: "#007bff" }}>
                  Rút tiền
                </Link>
              </b>
            </div>

            <div className="text_hello">
              <b
                style={{
                  color: "rgb(40, 167, 69)",
                  marginRight: "5px",
                }}
              >
                Số dư nông trại:{" "}
                <span style={{ color: "rgb(220, 53, 69)" }}>
                  {info.vnd.toLocaleString("vi")} VNĐ
                </span>
              </b>
            </div>
          </Fragment>
        ) : (
          <div className="text_hello">
            <b
              style={{
                color: "rgb(40, 167, 69)",
                marginRight: "5px",
              }}
            >
              Tài khoản:{" "}
              <span style={{ color: "#007bff" }}>
                <Link to="/account/signup">Đăng ký</Link>
                <span
                  style={{
                    margin: "0px 5px",
                  }}
                >
                  /
                </span>
                <Link to="/account/login">Đăng nhập</Link>
              </span>
            </b>
          </div>
        )}
        <OpenLayout
          handleShowNotify={handleShowNotify}
          showNotify={showNotify}
        />
        {info && (
          <DiemDanhLayout
            info={info}
            setInfo={setInfo}
            setUsername={setUsername}
          />
        )}
        {!info && Element}
      </div>
    </Fragment>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ApiLink, convertToDate, notify, Title } from "../../../utils/Title";
import UserLogo from "./UserLogo";
export default function HistoryXu({ username }) {
  let [historyList, setHistoryList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.user_history_xu + Title.origin;
  }, []);

  useEffect(() => {
    const getMucTieuHomNay = async () => {
      const response = await axios.get(`${ApiLink.domain + "/history"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setHistoryList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    };

    const handleAPIAll = async () => {
      await Promise.all([Promise.resolve(getMucTieuHomNay())]);
    };
    handleAPIAll();
  }, [navigate]);

  return (
    <Fragment>
      <div id="main">
        <UserLogo />
        <div className="page-content">
          <section
            className="row"
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          >
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div
                    className="col-lg-12 stretch-card"
                    style={{ padding: 0 }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <h4
                          className="card-title"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Lịch sử nhận thưởng
                        </h4>
                        <div className="table-responsive pt-3">
                          <table className="table table-bordered text-center">
                            <thead>
                              <tr>
                                <th>STT</th>
                                <th>Thời gian</th>
                                <th>Số tiền</th>
                                <th>Ghi chú</th>
                              </tr>
                            </thead>
                            <tbody>
                              {historyList.map((history, index) => {
                                if (
                                  index >= (page - 1) * maxShow &&
                                  index < page * maxShow
                                ) {
                                  return (
                                    <tr key={index} className="table-white">
                                      <td>{index + 1}</td>
                                      <td>
                                        {convertToDate(history.time_history)}
                                      </td>
                                      <td>
                                        {history.xu.toLocaleString("vi") +
                                          " đồng"}
                                      </td>
                                      <td>{history.note}</td>
                                    </tr>
                                  );
                                } else return "";
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="flex_center pagination_mg"
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <div>Tổng số: {historyList.length}</div>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={
                              historyList.length <= maxShow
                                ? 1
                                : historyList.length % maxShow === 0
                                ? Math.floor(historyList.length / maxShow)
                                : Math.floor(historyList.length / maxShow) + 1
                            }
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToDate, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";

export default function OrderHistoryAdmin({ username }) {
  let [orderHistoryList, setOrderHistoryList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 10;
  let navigate = useNavigate();
  let [keyword, setKeyword] = useState("");
  let [timeList, setTimeList] = useState([]);
  let [timeChoose, setTimeChoose] = useState(false);
  let [loading, isLoading] = useState(true);

  useEffect(() => {
    const months = [];
    const currentDate = new Date();
    let current_month = currentDate.getMonth() + 1;
    let current_year = currentDate.getFullYear();

    for (let i = 0; i < 6; i++) {
      if (current_month === 0) {
        current_month = 12;
        months.push(`${current_month}/${current_year - 1}`);
        current_year--;
      } else if (current_month < 10) {
        months.push(`${"0" + current_month}/${current_year}`);
      } else {
        months.push(`${current_month}/${current_year}`);
      }
      current_month--;
    }

    setTimeList(months);
    if (months.length > 0) {
      setTimeChoose(months[0]);
    }
  }, []);

  useEffect(() => {
    isLoading(true);
    async function getAllXuHistory() {
      const response = await axios.post(
        `${ApiLink.domain + "/check-order/admin/orders"}`,
        {
          time: timeChoose,
        },
        {
          withCredentials: true,
        }
      );
      isLoading(false);
      if (response.data.status) {
        setOrderHistoryList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    if (timeChoose) {
      getAllXuHistory();
    }
  }, [navigate, timeChoose]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return orderHistoryList.filter((item) => {
      return (
        item.browser.toLowerCase().includes(keyword) ||
        item.client_platform.toLowerCase().includes(keyword) ||
        item.merchant.toLowerCase().includes(keyword) ||
        item.utm_source.toLowerCase().includes(keyword) ||
        convertToDate(item.click_time).toLowerCase().includes(keyword) ||
        convertToDate(item.confirmed_time).toLowerCase().includes(keyword) ||
        convertToDate(item.sales_time).toLowerCase().includes(keyword) ||
        convertToDate(item.update_time).toLowerCase().includes(keyword)
      );
    });
  };

  return (
    <div id="main">
      <AdminLogo />
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm lịch sử"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách đơn hàng
                        </h4>

                        <div style={{ display: "flex" }}>
                          <select
                            style={{
                              width: "100px",
                            }}
                            onChange={(e) => setTimeChoose(e.target.value)}
                            className="form-select"
                            value={timeChoose}
                            aria-label="Default select example"
                          >
                            {timeList.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {loading ? (
                        ""
                      ) : (
                        <Fragment>
                          <div className="table-responsive pt-3">
                            <table className="table table-bordered text-center">
                              <thead>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>ID</th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Username
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Chiến dịch
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Thời gian click
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Thời gian ghi nhận
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Thời gian cập nhật
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Trạng thái
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filterSearch().map((history, index) => {
                                  if (
                                    index >= (page - 1) * maxShow &&
                                    index < page * maxShow
                                  ) {
                                    return (
                                      <tr key={index} className="table-white">
                                        <td>{index + 1}</td>
                                        <td>{history.utm_source}</td>
                                        <td>{history.merchant}</td>
                                        <td>
                                          {convertToDate(history.click_time)}
                                        </td>
                                        <td>
                                          {convertToDate(
                                            history.confirmed_time
                                          )}
                                        </td>
                                        <td>
                                          {convertToDate(history.update_time)}
                                        </td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          {history.order_pending ? (
                                            <span className="app_pending">
                                              Chờ duyệt
                                            </span>
                                          ) : history.is_confirmed &&
                                            history.order_approved ? (
                                            <span className="app_approved">
                                              Thành công
                                            </span>
                                          ) : history.order_approved ? (
                                            <span className="app_after_pending">
                                              Tạm duyệt
                                            </span>
                                          ) : (
                                            <span className="app_reject">
                                              Bị hủy
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  } else return "";
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="flex_center pagination_mg">
                            <div>Tổng số: {filterSearch().length}</div>
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="Next"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={2}
                              pageCount={
                                filterSearch().length <= maxShow
                                  ? 1
                                  : filterSearch().length % maxShow === 0
                                  ? Math.floor(filterSearch().length / maxShow)
                                  : Math.floor(
                                      filterSearch().length / maxShow
                                    ) + 1
                              }
                              previousLabel="Previous"
                              renderOnZeroPageCount={null}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                            />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

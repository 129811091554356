import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  ApiLink,
  notify,
  regexBankNumber,
  regexPhone,
  Title,
} from "../../../utils/Title";
import UserLogo from "./UserLogo";
import FormConfirm from "../AdminLayout/Form/FormConfirm";
import UserStatistic from "../BasicLayout/UserStatistic";
export default function Withdraw({ username }) {
  let [loading, isLoading] = useState(true);
  let [payMethodList, setPayMethodList] = useState([]);
  let [showFormConfirm, setShowFormConfirm] = useState(false);
  let [methodChoose, setMethodChoose] = useState({
    pay_method_id: null,
    data: {},
  });
  let [payDetailChoose, setPayDetailChoose] = useState(0);

  let [payInfo, setPayInfo] = useState({
    momo_phone: "",
    bank_id: "",
    bank_number: "",
    bank_fullname: "",
    xu: "",
    PayBank: "",
  });

  let navigate = useNavigate();

  useEffect(() => {
    document.title = Title.infoWithdraw + Title.origin;
  }, []);

  useEffect(() => {
    async function getInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        const { bank_id, bank_number, bank_fullname, momo_phone, xu, PayBank } =
          response.data.data;
        setPayInfo({
          bank_id,
          bank_number,
          bank_fullname,
          momo_phone,
          xu,
          PayBank,
        });
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }

    async function getPayMethodList() {
      const response = await axios.get(`${ApiLink.domain + "/pay-method"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        const pay_method_id = response.data.data[0].pay_method_id;
        setPayMethodList(response.data.data);
        if (response.data.data.length > 0) {
          const data = response.data.data.find(
            (method) => method.pay_method_id === pay_method_id
          );
          if (data) {
            setMethodChoose({
              pay_method_id,
              data,
            });
          } else {
            setMethodChoose({
              pay_method_id: null,
              data: [],
            });
          }
        }
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }
    const getAll = async () => {
      await Promise.all([Promise.resolve(getInfo())]);
      await Promise.all([Promise.resolve(getPayMethodList())]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  const handleChangePayInfo = (e) => {
    const { name, value } = e.target;
    setPayInfo({ ...payInfo, [name]: value });
  };

  const closeFormConfirm = () => {
    setShowFormConfirm(false);
  };

  const handleChangeMethodChoose = (pay_method_id) => {
    const data = payMethodList.find(
      (method) => method.pay_method_id === pay_method_id
    );
    if (data) {
      setMethodChoose({
        pay_method_id,
        data,
      });
    } else {
      setMethodChoose({
        pay_method_id: null,
        data: [],
      });
    }
    setPayDetailChoose(0);
  };

  const handleChangePayDetailChoose = (pay_detail_id) => {
    if (
      methodChoose.pay_method_id &&
      methodChoose.data.PayDetails &&
      methodChoose.data.PayDetails.length > 0
    ) {
      const data = methodChoose.data.PayDetails.find(
        (pay_detail) => pay_detail.pay_detail_id === Number(pay_detail_id)
      );

      if (data) {
        setPayDetailChoose(data);
      } else {
        setPayDetailChoose(null);
      }
    }
  };

  const handleWithdrawSubmit = async () => {
    if (!methodChoose.data || methodChoose.data.length < 0) {
      notify(false, "Vui lòng chọn phương thức đổi thưởng");
    } else if (!payDetailChoose) {
      notify(false, "Vui lòng chọn mệnh giá");
    } else if (
      methodChoose.data.pay_method_code === "BANK" &&
      (!payInfo.PayBank ||
        !regexBankNumber.test(payInfo.bank_number) ||
        String(payInfo.bank_fullname).trim().length < 5)
    ) {
      notify(false, "Vui lòng cập nhật đúng thông tin ngân hàng");
    } else if (
      methodChoose.data.pay_method_code === "MOMO" &&
      !regexPhone.test(payInfo.momo_phone)
    ) {
      notify(false, "Vui lòng cập nhật đúng SĐT Ví Momo");
    } else {
      setShowFormConfirm(true);
    }
  };

  const showContentWithdraw = () => {
    let content = "Xác nhận đổi thưởng: ";
    if (
      methodChoose.data.pay_method_code === "CARD_VIETTEL" ||
      methodChoose.data.pay_method_code === "CARD_VINA" ||
      methodChoose.data.pay_method_code === "CARD_MOBI"
    ) {
      content += payDetailChoose.name;
    } else if (methodChoose.data.pay_method_code === "MOMO") {
      content += payDetailChoose.name + ", " + payInfo.momo_phone;
    } else if (methodChoose.data.pay_method_code === "BANK") {
      content +=
        payDetailChoose.name +
        ", " +
        (payInfo.PayBank ? payInfo.PayBank.bank_code : "") +
        ", " +
        payInfo.bank_number +
        ", " +
        payInfo.bank_fullname;
    }

    return content;
  };
  return loading ? (
    <Fragment></Fragment>
  ) : (
    <div id="main">
      <UserLogo />
      {showFormConfirm ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {showFormConfirm ? (
            <FormConfirm
              itemChoose={payDetailChoose.pay_detail_id}
              status={"customer_withdraw"}
              closeFormConfirm={closeFormConfirm}
              id_handle={""}
              content={showContentWithdraw()}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="page-content page_withdraw">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {<UserStatistic username={username} />}
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body user_account">
                      <h4
                        className="card-title"
                        style={{ marginBottom: 20, textAlign: "center" }}
                      >
                        Rút tiền ATM / Thẻ cào / Ví Momo
                      </h4>
                      <div
                        className="col-12 mg_15"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="col-9"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div id="form_edit_info_submit">
                            <div className="gift_list">
                              {payMethodList.map((method, index) => {
                                return (
                                  <div
                                    onClick={() => {
                                      handleChangeMethodChoose(
                                        method.pay_method_id
                                      );
                                    }}
                                    key={index}
                                    className={
                                      method.pay_method_id ===
                                      methodChoose.pay_method_id
                                        ? "one_gift method_activd"
                                        : "one_gift"
                                    }
                                  >
                                    <img
                                      src={method.pay_method_image}
                                      alt="gift"
                                    />
                                    <div
                                      className="gift_name"
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {method.pay_method_name}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-9" style={{}}>
                            <div style={{ marginBottom: 20, marginTop: 20 }}>
                              <label className="form-label">
                                Số dư hiện tại
                              </label>
                              <input
                                disabled
                                className="form-control showordisable"
                                defaultValue={
                                  payInfo.xu.toLocaleString("vi") + " đồng"
                                }
                                type="text"
                                placeholder="Số dư hiện tại"
                              />
                            </div>

                            <div style={{ marginBottom: 20 }}>
                              <label className="form-label">
                                Chọn mệnh giá
                              </label>
                              <select
                                className="form-select"
                                name="pay_detail_id"
                                value={
                                  payDetailChoose
                                    ? Number(payDetailChoose.pay_detail_id)
                                    : 0
                                }
                                aria-label="Default select example"
                                onChange={(e) =>
                                  handleChangePayDetailChoose(e.target.value)
                                }
                              >
                                <option value={0}>Chọn mệnh giá</option>
                                {methodChoose.data &&
                                  methodChoose.data.PayDetails &&
                                  methodChoose.data.PayDetails.length > 0 &&
                                  methodChoose.data.PayDetails.map(
                                    (pay_detail, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={Number(
                                            pay_detail.pay_detail_id
                                          )}
                                        >
                                          {pay_detail.name +
                                            " = " +
                                            pay_detail.xu.toLocaleString("vi") +
                                            " đồng"}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>
                            </div>

                            {methodChoose.data &&
                              methodChoose.data.pay_method_code === "MOMO" && (
                                <div style={{ marginBottom: 20 }}>
                                  <label className="form-label">
                                    Số điện thoại ví Momo
                                  </label>
                                  <input
                                    disabled
                                    className="form-control showordisable"
                                    name="momo_phone"
                                    defaultValue={payInfo.momo_phone}
                                    type="text"
                                    placeholder="Nhập SĐT ví Momo"
                                  />
                                </div>
                              )}

                            {methodChoose.data &&
                              methodChoose.data.pay_method_code === "BANK" && (
                                <Fragment>
                                  <div style={{ marginBottom: 20 }}>
                                    <label className="form-label">
                                      Tên ngân hàng
                                    </label>
                                    <input
                                      disabled
                                      className="form-control showordisable"
                                      id="info_phone_input"
                                      name="bank_number"
                                      defaultValue={
                                        payInfo.PayBank
                                          ? payInfo.PayBank.bank_name || ""
                                          : ""
                                      }
                                      type="text"
                                      placeholder="Tên ngân hàng"
                                    />
                                  </div>
                                  <div style={{ marginBottom: 20 }}>
                                    <label className="form-label">
                                      Số tài khoản ngân hàng
                                    </label>
                                    <input
                                      disabled
                                      className="form-control showordisable"
                                      id="info_phone_input"
                                      name="bank_number"
                                      defaultValue={payInfo.bank_number}
                                      type="number"
                                      placeholder="Nhập STK ngân hàng"
                                      onChange={(e) => handleChangePayInfo(e)}
                                    />
                                  </div>
                                  <div style={{ marginBottom: 20 }}>
                                    <label className="form-label">
                                      Tên chủ tài khoản
                                    </label>
                                    <input
                                      disabled
                                      className="form-control showordisable"
                                      name="bank_fullname"
                                      defaultValue={payInfo.bank_fullname}
                                      type="text"
                                      placeholder="Nhập tên chủ tài khoản"
                                    />
                                  </div>
                                </Fragment>
                              )}

                            <div
                              style={{
                                marginBottom: 10,
                                textAlign: "center",
                                lineHeight: "22px",
                              }}
                            >
                              Nếu thông tin rút tiền bị sai, bạn bấm{" "}
                              <Link to={"/account/pay-info"}>vào đây</Link> để
                              cập nhật.
                            </div>

                            <div
                              style={{
                                marginBottom: 20,
                                textAlign: "center",
                                lineHeight: "22px",
                              }}
                            >
                              Thời gian thanh toán là từ 3-5 ngày làm việc.
                            </div>

                            <div
                              style={{
                                marginBottom: 20,
                                textAlign: "center",
                              }}
                            >
                              <button
                                type="button"
                                id="btn_edit_info"
                                className="btn btn-success"
                                onClick={() => handleWithdrawSubmit()}
                              >
                                Đổi thưởng
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

import React, { Fragment } from "react";
import { convertToDate } from "../../../../utils/Title";

export default function FormCodeLinkDetail({ itemChoose, closeForm }) {
  const taskHistoryState = {
    code_id: itemChoose.code_id,
    code: itemChoose.code,
    url: itemChoose.url,
    time_create: itemChoose.time_create,
    time_end: itemChoose.time_end,
    device_name: itemChoose.device_name,
    device_browser: itemChoose.device_browser,
    device_ip: itemChoose.device_ip,
    status_code: itemChoose.status_code,
    TaskHistorys: itemChoose.TaskHistorys,
  };

  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">Chi Tiết Mã Code</div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Mã code</label>
              <input
                disabled
                className="form-control showordisable"
                name="task_name"
                defaultValue={taskHistoryState.code}
                type="text"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Thời gian tạo</label>
              <input
                disabled
                className="form-control"
                name="time_create"
                defaultValue={convertToDate(taskHistoryState.time_create)}
                type="text"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Thời gian hết hạn</label>
              <input
                disabled
                className="form-control"
                name="time_end"
                type="text"
                defaultValue={convertToDate(taskHistoryState.time_end)}
              />
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Địa chỉ IP</label>
              <input
                disabled
                className="form-control"
                name="device_ip"
                type="text"
                defaultValue={taskHistoryState.device_ip}
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Mã code</label>
              <input
                disabled
                className="form-control"
                name="code"
                type="text"
                defaultValue={taskHistoryState.code}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                disabled
                className="form-select"
                name="status_code"
                defaultValue={taskHistoryState.status_code}
                aria-label="Default select example"
              >
                <option value={false}>Chưa dùng</option>
                <option value={true}>
                  Đã dùng {taskHistoryState.TaskHistorys.length + " lần"}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="col-12"
          style={{ padding: "0px 35px", margin: 0, marginBottom: 20 }}
        >
          <label className="form-label">Thông tin thiết bị - Code</label>
          <textarea
            className="form-control"
            id="user-address"
            name="device_name"
            type="text"
            rows="3"
            disabled
            defaultValue={
              taskHistoryState.device_name +
              " Địa chỉ IP: " +
              taskHistoryState.device_ip
            }
            placeholder="VD: Windows NT 10.0; Win64; x64"
          />
        </div>

        <div
          className="col-12"
          style={{ padding: "0px 35px", margin: 0, marginBottom: 20 }}
        >
          <label className="form-label">Thông tin trình duyệt - Code</label>
          <textarea
            className="form-control"
            id="user-address"
            name="device_browser"
            type="text"
            rows="3"
            disabled
            defaultValue={taskHistoryState.device_browser}
            placeholder="VD: Windows NT 10.0; Win64; x64"
          />
        </div>

        {taskHistoryState.TaskHistorys.length > 0 && (
          <Fragment>
            <div
              className="col-12"
              style={{ padding: "0px 35px", margin: 0, marginBottom: 20 }}
            >
              <label className="form-label">Thông tin thiết bị - Task</label>
              <textarea
                className="form-control"
                id="user-address"
                name="device_name_code"
                type="text"
                rows="3"
                disabled
                defaultValue={
                  taskHistoryState.TaskHistorys[0].device_name +
                  " Địa chỉ IP: " +
                  taskHistoryState.TaskHistorys[0].device_ip
                }
                placeholder="VD: Windows NT 10.0; Win64; x64"
              />
            </div>
            <div
              className="col-12"
              style={{ padding: "0px 35px", margin: 0, marginBottom: 20 }}
            >
              <label className="form-label">Thông tin trình duyệt - Task</label>
              <textarea
                className="form-control"
                id="user-address"
                name="device_browser_code"
                type="text"
                rows="3"
                disabled
                defaultValue={taskHistoryState.TaskHistorys[0].device_browser}
                placeholder="VD: Windows NT 10.0; Win64; x64"
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchTheme from "./SearchTheme";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { ApiLink, convertToDate, notify } from "../../../utils/Title";
import FormConfirm from "./Form/FormConfirm";
import AdminLogo from "./AdminLogo";

export default function OTPAdmin({ username }) {
  let [otpList, setOTPList] = useState([]);
  let [keyword, setKeyword] = useState("");
  let [page, setPage] = useState(1);
  let [confirm, setConfirm] = useState(false);
  const maxShow = 7;
  let navigate = useNavigate();

  const handleShowFormDetail = (token) => {
    alert(token);
  };

  const handleRemoveAllOTP = () => {
    setConfirm(true);
  };

  useEffect(() => {
    async function getAllOTP() {
      const response = await axios.get(`${ApiLink.domain}/admin/otp`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setOTPList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    
    getAllOTP();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const closeFormConfirm = () => {
    setConfirm(false);
  };

  const loadData = (data) => {
    setOTPList(data);
    setPage(1);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return otpList.filter((item) => {
      return (
        item.email.toLowerCase().includes(keyword) ||
        item.otp_code.toLowerCase().includes(keyword) ||
        item.time_send.toLowerCase().includes(keyword) ||
        Number(item.id) === Number(keyword)
      );
    });
  };

  return (
    <div id="main">
      <AdminLogo />
      {confirm ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          <FormConfirm
            status={"admin_delete_otp"}
            content={"Bạn chắc chắn muốn xóa tất cả các mã OTP"}
            closeFormConfirm={closeFormConfirm}
            loadData={loadData}
          />
        </div>
      ) : (
        ""
      )}
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            <SearchTheme title={"Tìm kiếm OTP"} handleSearch={handleSearch} />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách mã OTP{" "}
                        </h4>
                        <div style={{ display: "flex" }}>
                          <button
                            id="info"
                            className="btn btn-success btn_add"
                            onClick={() => handleRemoveAllOTP()}
                          >
                            <i className="fas fa-trash" />{" "}
                            <span>Xóa Tất Cả</span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>TIME</th>
                              <th style={{ whiteSpace: "nowrap" }}>OTP_CODE</th>
                              <th style={{ whiteSpace: "nowrap" }}>OTP_TYPE</th>
                              <th style={{ whiteSpace: "nowrap" }}>STATUS</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Xem chi tiết
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((otp, index) => {
                              return (
                                <tr key={index} className="table-white">
                                  <td>{otp.id}</td>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {convertToDate(otp.time_send)}
                                  </td>
                                  <td
                                    style={{
                                      color: "#de1e95",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {otp.otp_code.length < 7
                                      ? otp.otp_code
                                      : otp.otp_code.substr(0, 6)}
                                  </td>
                                  {otp.otp_type === 1 ? (
                                    <td
                                      style={{
                                        color: "#9694ff",
                                        fontWeight: 700,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Quên mật khẩu
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        color: "#ff7976",
                                        fontWeight: 700,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Xác thực email
                                    </td>
                                  )}
                                  {otp.status === true ? (
                                    <td
                                      style={{
                                        color: "#28a745",
                                        fontWeight: 700,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Thành công
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        color: "#ffc107",
                                        fontWeight: 700,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Chưa xác thực
                                    </td>
                                  )}

                                  <td>
                                    <button
                                      className="btn btn-success btn_change_status"
                                      onClick={() =>
                                        handleShowFormDetail(
                                          "OTP_CODE: " +
                                            otp.otp_code +
                                            "\nEmail: " +
                                            otp.email
                                        )
                                      }
                                    >
                                      Chi Tiết
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {otpList.length}</div>
                        <ReactPaginate
                          forcePage={page - 1}
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            otpList.length <= maxShow
                              ? 1
                              : otpList.length % maxShow === 0
                              ? Math.floor(otpList.length / maxShow)
                              : Math.floor(otpList.length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

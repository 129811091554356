import React, { useEffect, useState, memo } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ApiLink, notify } from "../../../utils/Title";

function UserStatistic({ username }) {
  let [loading, setLoading] = useState(false);
  let [thongKeTop, setThongKeTop] = useState({
    xu_earn: null,
    xu_withdraw: null,
    task_count: null,
  });
  let navigate = useNavigate();

  useEffect(() => {
    async function getThongKeTop() {
      const response = await axios.get(`${ApiLink.domain + "/statistic"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setThongKeTop(response.data.data[0]);
        setLoading(false);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else {
          return navigate("/", { replace: true });
        }
      }
    }
    getThongKeTop();
  }, [navigate]);

  return (
    <>
      {loading ? (
        ""
      ) : (
        <div
          className="row"
        >
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-3 py-4-5">
                <div className="row">
                  <div className="col-md-4">
                    <div className="stats-icon purple">
                      <i className="fas fa-battery-half text-c-red f-18" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h6 className="text-muted font-semibold">Số dư</h6>
                    <h6 className="mb-0">
                      {thongKeTop.xu_earn
                        ? Number(thongKeTop.xu_earn).toLocaleString("vi") + "đ"
                        : "0"}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-3 py-4-5">
                <div className="row">
                  <div className="col-md-4">
                    <div className="stats-icon blue">
                      <i className="fas fa-xu_withdraw text-c-blue f-18" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h6 className="text-muted font-semibold">Đã rút</h6>
                    {thongKeTop.xu_withdraw
                      ? Number(thongKeTop.xu_withdraw).toLocaleString("vi") +
                        "đ"
                      : "0"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-3 py-4-5">
                <div className="row">
                  <div className="col-md-4">
                    <div className="stats-icon green">
                      <i className="fas fa-utensils text-c-green f-18" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h6 className="text-muted font-semibold">Nhiệm vụ</h6>
                    {thongKeTop.task_count ||
                    Number(thongKeTop.task_count) === 0
                      ? Number(thongKeTop.task_count).toLocaleString("vi")
                      : "0"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-3 py-4-5">
                <div className="row">
                  <div className="col-md-4">
                    <div className="stats-icon red">
                      <i className="fas fa-user text-c-yellow f-18" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h6 className="text-muted font-semibold">
                      {" "}
                      <Link to="/account/login">{username}</Link>
                    </h6>
                    <h6 className="mb-0" style={{ fontSize: 14 }}>
                      <span>
                        <Link to="/account/logout">Logout</Link>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(UserStatistic);

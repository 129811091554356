import React, { Fragment } from "react";
import { notify } from "../../../utils/Title";

export default function InputUsernameLayout({
  setUsernameInput,
  usernameInput,
  checkUsername,
}) {
  return (
    <Fragment>
      <div className="input_username_container">
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            whiteSpace: "nowrap",
          }}
        >
          Điền Username:
        </b>
        <input
          onChange={(e) => {
            setUsernameInput(e.target.value);
          }}
          className="input_username_app"
          placeholder="Ví dụ: deptrai37"
        />
      </div>

      <div className="btn_create_username">
        <span
          className="btn_create_username-content"
          onClick={() => {
            if (
              usernameInput.trim() === "" ||
              usernameInput.trim().length < 3
            ) {
              notify(false, "Username phải có từ 3 ký tự trở lên! VD ngoc123");
            } else {
              checkUsername(usernameInput);
            }
          }}
        >
          Tạo username
        </span>
      </div>
    </Fragment>
  );
}

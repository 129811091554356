import React, { Fragment } from "react";
import { notify } from "../../../utils/Title";

export default function InputUsernameOrderLayout({
  setUsernameInput,
  usernameInput,
  checkUsername,
  timeList,
  timeChoose,
  setTimeChoose,
}) {
  return (
    <Fragment>
      <div className="input_username_container">
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            whiteSpace: "nowrap",
          }}
        >
          Điền Username:
        </b>
        <input
          onChange={(e) => {
            setUsernameInput(e.target.value);
          }}
          className="input_username_app"
          placeholder="Ví dụ: deptrai37"
        />
      </div>

      <div
        className="input_username_container"
        style={{
          marginTop: "10px",
        }}
      >
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
            whiteSpace: "nowrap",
          }}
        >
          Thời gian tra cứu:
        </b>
        <select
          style={{
            width: "100px",
          }}
          onChange={(e) => setTimeChoose(e.target.value)}
          className="form-select"
          value={timeChoose}
          aria-label="Default select example"
        >
          {timeList.map((time, index) => (
            <option key={index} value={time}>{time}</option>
          ))}
        </select>
      </div>

      <div className="btn_create_username">
        <span
          className="btn_create_username-content"
          onClick={() => {
            if (
              usernameInput.trim() === "" ||
              usernameInput.trim().length < 3
            ) {
              notify(false, "Username phải có từ 3 ký tự trở lên! VD ngoc123");
            } else {
              checkUsername(usernameInput);
            }
          }}
        >
          Kiểm tra
        </span>
      </div>
    </Fragment>
  );
}

import React, { Fragment, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ApiLink, notify } from "../../../../utils/Title";

export default function Logout() {
  let navigate = useNavigate();
  useEffect(() => {
    async function handleLogout() {
      const response = await axios.get(
        `${ApiLink.domain + "/account/logout"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        notify(true, response.data.message);
        return navigate("/account/login", { replace: true });
      }
    }
    handleLogout();
  }, [navigate]);

  return <Fragment></Fragment>;
}

import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormAddSeed({ closeForm, loadData }) {
  let navigate = useNavigate();
  let [userState, setUserState] = useState({
    seed_name: "",
    xu: "",
    note: "",
    time_thuhoach: "",
    sell: "",
    sell_bitrom: "",
    image_trongcay: "",
    avatar: "",
    status: "",
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (
      name === "time_thuhoach" ||
      name === "xu" ||
      name === "sell_bitrom" ||
      name === "status" ||
      name === "sell"
    ) {
      value = Number(value);
    }
    setUserState({ ...userState, [name]: value });
  };

  const editUser = async (newUser) => {
    const response = await axios.post(
      `${ApiLink.domain + "/admin/farm-seed/"}`,
      newUser,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeForm(true, false);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleEditUser = async () => {
    if (userState.seed_name.trim() < 3) {
      notify(false, "Tên hạt giống cần >= 3 ký tự");
      return;
    }
    if (userState.avatar.trim() < 1) {
      notify(false, "Chưa có hình ảnh hạt giống");
      return;
    }
    if (userState.image_trongcay.trim() < 1) {
      notify(false, "Chưa có hình ảnh cây trồng");
      return;
    } else if (
      userState.status !== 1 &&
      userState.status !== 0 &&
      userState.status !== false &&
      userState.status !== true
    ) {
      notify(false, "Vui lòng chọn trạng thái");
      return;
    } else if (!Number.isInteger(userState.xu) || Number(userState.xu) < 0) {
      notify(false, "Số xu không hợp lệ");
      return;
    } else if (
      !Number.isInteger(userState.sell) ||
      Number(userState.sell) < 0
    ) {
      notify(false, "Số xu bán không hợp lệ");
      return;
    } else if (
      !Number.isInteger(userState.sell_bitrom) ||
      Number(userState.sell_bitrom) < 0
    ) {
      notify(false, "Số xu bán trộm không hợp lệ");
      return;
    } else if (
      !Number.isInteger(userState.time_thuhoach) ||
      Number(userState.time_thuhoach) < 0
    ) {
      notify(false, "Thời gian thu hoạch không hợp lệ");
      return;
    }
    const newUser = {
      seed_name: userState.seed_name.trim(),
      note: userState.note.trim(),
      avatar: userState.avatar.trim(),
      image_trongcay: userState.image_trongcay.trim(),
      status: userState.status ? true : false,
      xu: Number(userState.xu),
      sell: Number(userState.sell),
      sell_bitrom: Number(userState.sell_bitrom),
      time_thuhoach: Number(userState.time_thuhoach),
    };
    await editUser(newUser);
  };
  return (
    <div
      className="col-6 add_edit_class"
      style={{
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        display: "block",
      }}
    >
      <div
        className="bg-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <div className="text-white add_book_class_header">
          Cập Nhật Thông Tin Người Dùng
        </div>
        <div onClick={() => closeForm(false, false)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>
      <div className="sroll_form style-15">
        <div style={{ padding: 20, display: "flex" }}>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Tên hạt giống</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control showordisable"
                name="seed_name"
                defaultValue={userState.seed_name}
                type="text"
                placeholder="VD: Lúa"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Hình ảnh trồng</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="image_trongcay"
                type="text"
                placeholder="VD: 1"
                defaultValue={userState.image_trongcay}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Hình ảnh hạt giống</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="avatar"
                type="text"
                placeholder="VD: 1"
                defaultValue={userState.avatar}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Trạng thái</label>
              <select
                onChange={(e) => handleOnChange(e)}
                className="form-select"
                name="status"
                value={userState.status}
                aria-label="Default select example"
              >
                <option value={1}>Đang bán</option>
                <option value={0}>Ngưng bán</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Thời gian thu hoạch</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="time_thuhoach"
                type="number"
                defaultValue={userState.time_thuhoach}
                placeholder="VD: 1440"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá mua</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="xu"
                type="number"
                defaultValue={userState.xu}
                placeholder="VD: 10"
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá bán thường</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="sell"
                type="number"
                defaultValue={userState.sell}
                placeholder="VD: 720"
              />
            </div>

            <div style={{ marginBottom: 20 }}>
              <label className="form-label">Giá bán trộm</label>
              <input
                onChange={(e) => handleOnChange(e)}
                className="form-control"
                name="sell_bitrom"
                type="number"
                defaultValue={userState.sell_bitrom}
                placeholder="VD: 360"
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ padding: "0px 35px", margin: 0 }}>
          <label className="form-label">Ghi chú</label>
          <textarea
            onChange={(e) => handleOnChange(e)}
            className="form-control style-15"
            name="note"
            type="text"
            rows="3"
            value={userState.note}
            placeholder="VD: Lúa"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <button
            onClick={() => handleEditUser()}
            className="btn btn-success btn_add_edit_customer_submit"
          >
            Xác Nhận
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, convertToTimeFarm, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";

export default function FormFarmStore({ status, closeFormAdd, loadData }) {
  let [farmSeedList, setFarmSeedList] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    async function getAllFarmSeed() {
      const response = await axios.get(`${ApiLink.domain + "/farm-seed"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        const list = response.data.data.map((item) => ({
          ...item,
          quanty: 0,
        }));
        setFarmSeedList(list);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }
    getAllFarmSeed();
  }, [navigate]);

  const handleOnChange = (e, seed_id) => {
    let { value } = e.target;
    const new_list = farmSeedList.map((item) => ({
      ...item,
      quanty:
        item.seed_id === Number(seed_id) ? Number(value) : Number(item.quanty),
    }));
    setFarmSeedList(new_list);
  };

  const buyFarmSeed = async (seed_id, quanty) => {
    const response = await axios.put(
      `${ApiLink.domain + "/user-seed/" + seed_id}`,
      { quanty },
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data);
      closeFormAdd();
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleBuyFarmSeed = async (seed_id) => {
    const farm_seed = farmSeedList.find(
      (item) => item.seed_id === Number(seed_id)
    );
    if (!farm_seed) {
      notify(false, "Vui lòng chọn hạt giống mua");
      return;
    }
    if (
      !farm_seed.quanty ||
      Number(farm_seed.quanty) <= 0 ||
      Number(farm_seed.quanty) > 10
    ) {
      notify(false, "Số lượng không hợp lệ");
      return;
    }

    await buyFarmSeed(seed_id, Number(farm_seed.quanty));
  };

  console.log(farmSeedList);

  return (
    <div
      className="row col-9 add_edit_class"
      style={{
        display: "block",
        backgroundColor: "rgb(242, 247, 255)",
        borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        margin: 0,
      }}
    >
      <div
        className="bg-primary row"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
          margin: 0,
        }}
      >
        <div className="text-white add_book_class_header">Cửa hàng</div>
        <div onClick={() => closeFormAdd()}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>

      <div
        className="row col-12 sroll_form style-15 mobile_min_width_100 mobile_no_wrap"
        style={{
          padding: "0px !important",
          display: "flex",
          flexDirection: "row",
          margin: 0,
        }}
      >
        <div
          className="row col-12 mobile_min_width_100"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="shop_list">
            {farmSeedList.map((farm_seed, index) => {
              return (
                <div key={index} className="one_farm_seed">
                  <div className="one_farm_seed__top">
                    <img
                      id="raucu"
                      src={"/images/farm/item/" + farm_seed.avatar + ".png"}
                      alt="rau_cu"
                    />
                    <div>
                      <b>{"[ " + farm_seed.seed_name + " ]"}</b>
                      <div className="one_farm_seed__price">
                        Giá: <b>{farm_seed.xu}</b> xu / cây
                      </div>
                    </div>
                  </div>{" "}
                  <div className="one_farm_seed__mid">
                    <div>
                      Thời Gian Sinh truởng:{" "}
                      {"[ " + convertToTimeFarm(farm_seed.time_thuhoach) + " ]"}
                    </div>
                    <div>
                      Số Lượng Thu hoạch: {"[ " + farm_seed.sell + " cây ]"}
                    </div>
                    <div>Tổng thu nhập {"[ " + farm_seed.sell + " Xu ]"}</div>
                  </div>
                  <div className="one_farm_seed__bottom">
                    <div>Số Luợng Mua</div>
                    <input
                      type="number"
                      onChange={(e) => {
                        handleOnChange(e, farm_seed.seed_id);
                      }}
                    />
                    <div
                      className="one_farm_seed__buy"
                      onClick={() => {
                        handleBuyFarmSeed(farm_seed.seed_id);
                      }}
                    >
                      Mua
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      ></div>
    </div>
  );
}

import React from "react";

export default function NotifyLayout() {
  return (
    <div
      style={{
        border: "2px dashed #17a2b8",
        padding: "10px 5px",
        marginBottom: "10px",
        borderRadius: "5px",
      }}
    >
      <div className="paypal_price">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/icons/new.gif" alt="new" />
          <span
            style={{
              fontWeight: "bold",
              margin: "0px 5px",
            }}
          >
            Bảng giá thu mua PayPal
          </span>
          <img src="/images/icons/new.gif" alt="new" />
        </div>
        <div className="text_hello">
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            + Bán dưới 5$:{" "}
            <span style={{ color: "#dc3545" }}>Thu mua 21K/$</span>
          </b>
        </div>
        <div className="text_hello">
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            + Bán từ 5$: <span style={{ color: "#dc3545" }}>Thu mua 22K/$</span>
          </b>
        </div>
      </div>
      <div className="card_price">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/icons/co-viet-nam.gif"
            alt="new"
            style={{
              height: "20px",
            }}
          />
          <span
            style={{
              fontWeight: "bold",
              margin: "0px 5px",
            }}
          >
            Bảng giá bán Card
          </span>
          <img
            src="/images/icons/co-viet-nam.gif"
            alt="new"
            style={{
              height: "20px",
            }}
          />
        </div>
        <div className="text_hello">
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            Thẻ cào mọi mệnh giá:{" "}
            <span style={{ color: "#dc3545" }}>Chiết khấu 5%</span>
          </b>
        </div>
        <div className="text_hello">
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            + Thẻ cào 100K:{" "}
            <span style={{ color: "#dc3545" }}>Chỉ còn 95K</span>
          </b>
        </div>
        <div className="text_hello">
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            + Thẻ cào 500K:{" "}
            <span style={{ color: "#dc3545" }}>Chỉ còn 475K</span>
          </b>
        </div>
      </div>

      <div className="text_hello">
        <b
          style={{
            color: "rgb(40, 167, 69)",
            marginRight: "5px",
          }}
        >
          Zalo mua bán 24/7:{" "}
          <span
            onClick={() => {
              alert("SĐT Zalo: 0378544081 - Đức Ngọc");
            }}
            style={{ color: "#007bff", cursor: "pointer" }}
          >
            Đức Ngọc
          </span>
        </b>
      </div>
    </div>
  );
}

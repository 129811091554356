import React, { Fragment, useEffect, useState } from "react";
import { socket } from "../../socket";
import "../../styles/Chat.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import Footer from "../../components/Layouts/BasicLayout/Footer";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import { ApiLink, notify, Title } from "../../utils/Title";

export default function ChatPage() {
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [input, setInput] = useState("");
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    document.title = Title.mobile_app + Title.origin;
  }, []);

  const sendMessage = () => {
    if (!input || typeof input !== "string") {
      notify(false, "Bạn chưa điền nội dung chát");
      return;
    } else if (input.trim() === "") {
      notify(false, "Bạn chưa điền nội dung chát");
      return;
    } else if (input.trim().length > 255) {
      notify(false, "Nội dung chát quá dài :(");
      return;
    }
    socket.emit("sendMessage", input);
    setInput("");
  };

  const handleDeleteAllChat = async () => {
    const response = await axios.delete(`${ApiLink.domain + "/admin/chat"}`, {
      withCredentials: true,
    });
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setChatList(response.data.data);
    } else {
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/account/login", { replace: true });
      }
    }
  };

  const handleDeleteChat = async (chat_id) => {
    const response = await axios.delete(
      `${ApiLink.domain + "/admin/chat/" + chat_id}`,
      {
        withCredentials: true,
      }
    );
    notify(response.data.status, response.data.message);
    if (response.data.status) {
      setChatList(response.data.data);
    } else {
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/account/login", { replace: true });
      }
    }
  };

  const handleShowTimeChat = (time) => {
    const current_time = new Date();
    const chat_time = new Date(time);
    const time_sub = current_time - chat_time;
    if (time_sub / (365 * 24 * 60 * 60 * 1000) >= 1) {
      return Math.floor(time_sub / (24 * 60 * 60 * 1000)) + " năm trước";
    } else if (time_sub / (24 * 60 * 60 * 1000) >= 1) {
      return Math.floor(time_sub / (24 * 60 * 60 * 1000)) + " ngày trước";
    } else if (time_sub / (60 * 60 * 1000) >= 1) {
      return Math.floor(time_sub / (60 * 60 * 1000)) + " giờ trước";
    } else if (time_sub / (60 * 1000) >= 1) {
      return Math.floor(time_sub / (60 * 1000)) + " phút trước";
    } else if (time_sub / 1000 >= 1) {
      return Math.floor(time_sub / 1000) + " giây trước";
    }
    return "vài giây trước";
  };

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  useEffect(() => {
    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username);
        setInfo(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
      }
    }

    async function getChatList() {
      const response = await axios.get(`${ApiLink.domain + "/chat"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setChatList(response.data.data);
      } else {
        // notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
      }
    }

    const getAll = async () => {
      await Promise.all([Promise.resolve(getUserInfo())]);
      await Promise.all([Promise.resolve(getChatList())]);
      isLoading(false);
    };
    getAll();

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    const handleMessage = (message) => {
      setChatList((prevchatList) => [message, ...prevchatList]);
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("receiveMessage", handleMessage);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("receiveMessage", handleMessage);
    };
  }, [navigate]);

  return loading || !isConnected ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="chat_container">
        <UserBoardLayout
          Element={null}
          info={info}
          setInfo={setInfo}
          username={username}
          setUsername={setUsername}
          handleShowNotify={handleSetShowNotify}
          showNotify={showNotify}
        />
        {showNotify && <NotifyLayout />}
        <div className="chat_content">
          <div className="chat_name">Chém gió</div>
          <div className="chat_input">
            <textarea
              placeholder="Vui lòng viết tiếng việt có dấu để tôn trọng người đọc nhóe :D"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <div>
              <div className="btn_sent" onClick={sendMessage}>
                Gửi
              </div>
              {info.role_id === 1 && (
                <div className="btn_delete_chat" onClick={handleDeleteAllChat}>
                  Xóa tất cả
                </div>
              )}
            </div>
          </div>
          <div className="chat_list" id="style-15">
            {chatList.map((chat, index) => (
              <div key={index} className="one_message">
                <div className="image_user">
                  <img
                    src={
                      chat.User.username === "admin"
                        ? "/images/farm/user_admin.gif"
                        : chat.User.user_image
                        ? "/images/farm/user/" + chat.User.user_image + ".gif"
                        : "/images/farm/user/1.gif"
                    }
                    alt="user_image"
                  />
                </div>
                <div className="message_user">
                  <div className="user_info">
                    <img
                      src={
                        chat.User.username === "admin"
                          ? "/images/farm/on.png"
                          : "/images/farm/off.png"
                      }
                      alt="user_image"
                    />
                    <div
                      style={{
                        flex: 1,
                      }}
                      className="user_name"
                    >
                      {chat.User.username}
                    </div>
                    {info.role_id === 1 && (
                      <div
                        style={{
                          textAlign: "right",
                          cursor: "pointer",
                          color: "blue",
                        }}
                        onClick={() => {
                          handleDeleteChat(chat.chat_id);
                        }}
                      >
                        Xóa
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      chat.User.username === "admin"
                        ? "user_message txt_red"
                        : "user_message"
                    }
                  >
                    {chat.content}
                  </div>

                  <div className="message_time">
                    {"(" + handleShowTimeChat(chat.createdAt) + ")"}
                  </div>
                </div>
              </div>
            ))}
            <Footer margin_top={"15px"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

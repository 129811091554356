import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, Title } from "../../../../utils/Title";
import { useParams } from "react-router-dom";
import LoginLayout from "../LoginLayout";
export default function VerifyEmailLayout() {
  let [message, setMessage] = useState("");
  let [loading, isLoading] = useState(true);
  let { email, token } = useParams();

  useEffect(() => {
    document.title = Title.verifyEmail + Title.origin;
    const checkResult = async () => {
      const response = await axios.get(
        `${ApiLink.domain + "/account/verify/email/" + email + "/" + token}`,
        {
          withCredentials: true,
        }
      );
      setMessage(response.data.message);
      isLoading(false);
    };
    checkResult();
  }, [email, token]);

  return loading ? (
    <Fragment></Fragment>
  ) : (
    <div style={{ paddingLeft: 20 }}>
      <div
        className="login_signin"
        style={{
          color: "red",
          fontWeight: "bold",
          textAlign: "center",
          lineHeight: "22px",
        }}
      >
        {message}
      </div>
      <LoginLayout />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import SearchTheme from "./SearchTheme";
import { useNavigate } from "react-router-dom";
import { ApiLink, notify } from "../../../utils/Title";
import AdminLogo from "./AdminLogo";
import FormEditSeed from "./Form/FormEditSeed";
import FormAddSeed from "./Form/FormAddSeed";

export default function FarmSeedAdmin({ username }) {
  let [seedList, setSeedList] = useState([]);
  let [page, setPage] = useState(1);
  const maxShow = 7;
  let navigate = useNavigate();
  let [itemEdit, setItemEdit] = useState(false);
  let [itemAdd, setItemAdd] = useState(false);
  let [keyword, setKeyword] = useState("");

  const handleShowFormEdit = (item) => {
    setItemEdit(item);
  };

  const handleShowFormAdd = () => {
    setItemAdd(true);
  };

  const handleCloseFormEdit = async (edit_submit, result) => {
    if (result & !edit_submit) {
      await reloadData();
    }
    setItemEdit(false);
  };

  useEffect(() => {
    async function getAllUser() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/farm-seed"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setSeedList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
    getAllUser();
  }, [navigate]);

  const handlePageClick = (event) => {
    if (page !== event.selected + 1) setPage(event.selected + 1);
  };

  const loadData = (data) => {
    setSeedList(data);
  };

  const reloadData = async () => {
    const response = await axios.get(`${ApiLink.domain + "/admin/user"}`, {
      withCredentials: true,
    });
    if (response.data.status) {
      setSeedList(response.data.data);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
        return navigate("/", { replace: true });
      } else if (response.data.must === "permission") {
        return navigate("/", { replace: true });
      }
    }
  };

  const handleCloseFormAdd = async (add_edit, result) => {
    if (result & !add_edit) {
      // reload_data
      await reloadData();
    }
    setItemAdd(false);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword.toLowerCase().trim());
  };

  const filterSearch = () => {
    return seedList.filter((item) => {
      return (
        item.seed_name.toLowerCase().includes(keyword) ||
        item.note.toLowerCase().includes(keyword) ||
        item.image_trongcay.toLowerCase().includes(keyword) ||
        item.avatar.toLowerCase().includes(keyword) ||
        Number(item.xu) === Number(keyword) ||
        Number(item.time_thuhoach) === Number(keyword) ||
        Number(item.sell) === Number(keyword) ||
        Number(item.sell_bitrom) === Number(keyword) ||
        Number(item.status) === Number(keyword) ||
        Number(item.seed_id) === Number(keyword)
      );
    });
  };

  return (
    <div id="main">
      {" "}
      <AdminLogo />
      {itemAdd || itemEdit ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {itemEdit ? (
            <FormEditSeed
              itemChoose={itemEdit}
              closeForm={handleCloseFormEdit}
              loadData={loadData}
            />
          ) : (
            ""
          )}

          {itemAdd ? (
            <FormAddSeed
              closeForm={handleCloseFormAdd}
              loadData={loadData}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="page-content">
        <section
          className="row"
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <div className="col-12 col-lg-12">
            {/* <AdminThemeTop username={username} /> */}
            <SearchTheme
              title={"Tìm kiếm hạt giống"}
              handleSearch={handleSearch}
            />
            <div className="row">
              <div className="col-12">
                <div className="col-lg-12 stretch-card" style={{ padding: 0 }}>
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          className="card-title"
                          style={{ marginBottom: "0 !important" }}
                        >
                          Danh sách hạt giống
                        </h4>

                        <div style={{ display: "flex" }}>
                          <button
                            id="info"
                            className="btn btn-success btn_add"
                            onClick={() => handleShowFormAdd()}
                          >
                            <i className="fas fa-plus" />{" "}
                            <span>Thêm hạt giống</span>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              <th style={{ whiteSpace: "nowrap" }}>ID</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Tên hạt giống
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Ghi chú</th>
                              <th style={{ whiteSpace: "nowrap" }}>Giá mua</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Giá bán thường
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Giá bán trộm
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Thu hoạch
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Ảnh</th>
                              <th style={{ whiteSpace: "nowrap" }}>
                                Trạng thái
                              </th>
                              <th style={{ whiteSpace: "nowrap" }}>Cập nhật</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterSearch().map((user, index) => {
                              if (
                                index >= (page - 1) * maxShow &&
                                index < page * maxShow
                              ) {
                                return (
                                  <tr key={index} className="table-white">
                                    <td>{user.seed_id}</td>
                                    <td>{user.seed_name}</td>
                                    <td>{user.note}</td>
                                    <td>
                                      {Number(user.xu).toLocaleString("vi")}
                                    </td>
                                    <td>
                                      {Number(user.sell).toLocaleString("vi")}
                                    </td>
                                    <td>
                                      {Number(user.sell_bitrom).toLocaleString(
                                        "vi"
                                      )}
                                    </td>
                                    <td>{user.time_thuhoach}</td>
                                    <td>{user.avatar}</td>
                                    {user.status === 1 ? (
                                      <td
                                        style={{
                                          color: "#28a745",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Đang bán
                                      </td>
                                    ) : (
                                      <td
                                        style={{
                                          color: "#dc3545",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Ngưng bán
                                      </td>
                                    )}
                                    <td>
                                      <button
                                        onClick={() => handleShowFormEdit(user)}
                                        type="button"
                                        className="btn btn-warning m-1 btn_edit"
                                      >
                                        <i className="far fa-edit" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              } else return "";
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex_center pagination_mg">
                        <div>Tổng số: {filterSearch().length}</div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          pageCount={
                            filterSearch().length <= maxShow
                              ? 1
                              : filterSearch().length % maxShow === 0
                              ? Math.floor(filterSearch().length / maxShow)
                              : Math.floor(filterSearch().length / maxShow) + 1
                          }
                          previousLabel="Previous"
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
